const APP_ENV = process.env.APP_ENV
const base = {
  // dev: 'https://ota.uat.jaka.com/admin',
  dev: '/admin',
  // dev: 'https://ota.jaka.com/admin-test',
  sit: 'https://ota.uat.jaka.com/admin',
  prd: 'https://ota.jaka.com/admin',
}
const wsDomain = {
  dev: 'wss://ota.jaka.com:31002',
  sit: 'wss://ota.jaka.com:31002',
  prd: 'wss://ota.jaka.com',
}

const activity = {
  dev: '/market-activity',
  // dev: 'https://ota.jaka.com/admin-test/activity',
  sit: 'https://ota.uat.jaka.com/market-activity',
  prd: 'https://ota.jaka.com/admin',
}

const query = {
  // dev: 'https://ota.jaka.com/query-test',
  dev: '/query',
  sit: 'https://ota.jaka.com/query-test',
  prd: 'https://ota.jaka.com/query',
}

const bi = {
  dev: '/ota-bi',
  sit: 'https://ota.jaka.com/ota-data/ota-bi',
  prd: 'https://ota.jaka.com/ota-data/ota-bi',
}

const virtual = {
  dev: '/virtual',
  sit: 'https://ota.uat.jaka.com/virtual',
  prd: 'https://ota.jaka.com/virtual',
}
console.log('APP_ENV', APP_ENV)

const baseDomain = base[APP_ENV] || base.prd;
const activityDomain = activity[APP_ENV] || activity.prd;
const queryDomain = query[APP_ENV] || query.prd;
const biDomain = bi[APP_ENV] || bi.prd;
const virtualDomain = virtual[APP_ENV] || virtual.prd;

const urlMap = {
  register: baseDomain + '/user/register', // 用户注册
  login: baseDomain + '/user/login', // 用户登录
  logout: baseDomain + '/user/logout', // 用户登出
  validCode: baseDomain + '/user/captcha', // 验证码
  weiXinLogin: baseDomain + '/user/weiXinLogin', // weiXinLogin
  getUserInfo: baseDomain + '/user/getUserInfo', // 获取用户信息
  getUserList: baseDomain + '/user/userList', // 用户列表
  exportUserList: baseDomain + '/user/export', // 导出用户列表
  setInvalidUser: baseDomain + '/user/setInvalidUser', // 设置无效用户/激活用户

  // 用户权限
  setPassword: baseDomain + '/role/setPassword', // 设置用户密码
  getRoleList: baseDomain + '/role/list', // 角色列表
  addRole: baseDomain + '/role/add', // 增加角色
  getRoleMenuList: baseDomain + '/role/getRoleMenuList', // 获取角色菜单
  setRoleMenu: baseDomain + '/role/setMenu', // 设置角色菜单

  getUserMenus: baseDomain + '/role/getMenu', // 获取当前登录用户的菜单
  setUserRole: baseDomain + '/role/setUserRole', // 给用户添加角色
  removeUserRole: baseDomain + '/role/removeUserRole', // 删除用户的角色
  getUserMenuList: baseDomain + '/role/getUserMenuList', // 获取用户菜单
  
  // 机器人权限
  permissionList: baseDomain + '/robotPermission/permissionList', // 权限列表
  changePermission: baseDomain + '/robotPermission/changePermission', // 
  deletePermission: baseDomain + '/robotPermission/deletePermission', // 
  requestList: baseDomain + '/robotPermission/requestList', // 权限申请列表
  permissionRequest: baseDomain + '/robotPermission/addPermissionRequest', // 权限申请
  permissionRequestBatch: baseDomain + '/robotPermission/addPermissionRequestBatch', // 权限批量申请
  updatePermissionRequest: baseDomain + '/robotPermission/updatePermissionRequest', // 修改权限申请
  addPermission: baseDomain + '/robotPermission/addPermission', // 权限审批
  addPermissionBatch: baseDomain + '/robotPermission/addPermissionBatch', // 权限批量审批
  // 配置接口
  getAliOssKey: baseDomain + '/getAliOssKey', // 获取阿里OSS密钥
  uploadFile: baseDomain + '/upload', // 上传文件
  getArea: baseDomain + '/enum/area', // 获取省市区编码
  getRobotStatusMap: baseDomain + '/enum/robotStatus', // 机器人状态下拉列表
  getIndustryList: baseDomain + '/enum/industryList', // 行业下拉列表
  getCompanyList: baseDomain + '/enum/companyList', // 公司下拉列表
  // 工单
  getWorkOrderList: baseDomain + '/workOrder/workOrderList', // 工单列表
  updateWorkOrder: baseDomain + '/workOrder/updateWorkOrder', // 处理工单
  // OTA申请
  getAllApplyList: baseDomain + '/userOtaApply/getAllApplyList', // ota申请列表
  dealUserOtaApply: baseDomain + '/userOtaApply/dealUserOtaApply', // ota申请处理
  // 台账
  getLedgerList: baseDomain + '/ledger/ledgerList', // 台账列表
  addLedger: baseDomain + '/ledger/addLedger', // 新增台账
  updateLedger: baseDomain + '/ledger/updateLedger', // 编辑台账
  deleteLedger: baseDomain + '/ledger/deleteLedger', // 删除台账
  // 版本管理
  getVersionPlatformList: baseDomain + '/version/getVersionPlatformList', // 获取平台列表
  getVersionProductList: baseDomain + '/version/getVersionProductList', // 获取产品类型列表
  getVersionMappingList: baseDomain + '/version/getVersionMappingList', // 获取版本映射列表
  saveVersionMapping: baseDomain + '/version/saveVersionMapping', // 保存版本映射
  updateVersionMappering: baseDomain + '/version/updateVersionMappering', // 保存版本映射
  deleteVersionMapping: baseDomain + '/version/deleteVersionMapping', // 删除版本关联

  getPackageList: baseDomain + '/version/getPackageList', // 获取软件安装包列表
  getPackageListByVersion: baseDomain + '/version/getPackageListByVersion', // 根据版本号获取安装包
  getVersionMapping: baseDomain + '/version/getVersionMapping', // 获取配套软件包
  addVersionPackage: baseDomain + '/version/addVersionPackageV2', // 增加安装包
  deleteVersionPackage: baseDomain + '/version/deleteVersionPackage', // 删除版本管理

  // 机器人管理
  getRobotList: baseDomain + '/assets/robotList', // 机器人分页列表
  getCabinetList: baseDomain + '/assets/cabinetList', // 控制柜分页列表
  addRobot: baseDomain + '/assets/addRobot', // 新增机器人
  addCabinet: baseDomain + '/assets/addCabinet', // 新增控制柜
  updateRobot: baseDomain + '/assets/updateRobot', // 编辑机器人
  updateCabinet: baseDomain + '/assets/updateCabinet', // 编辑控制柜
  getAssociationCabinet: baseDomain + '/ledger/getAssociation', // 获取关联控制柜列表
  setOtaIsShow: baseDomain + '/assets/setIsShow', // 设置显示小程序OTA状态
  cancelOtaIsShow: baseDomain + '/assets/cancelIsShow', // 取消显示小程序OTA状态

  getRobotStatus: baseDomain + '/status/robot', // 机器人本体状态
  getCabinetStatus: baseDomain + '/status/cabinet', // 控制柜状态
  getJointStatus: baseDomain + '/status/joint', // 机器人关节状态

  getEventPower: baseDomain + '/event/power', // 上电
  getEventEnable: baseDomain + '/event/enable', // 上使能
  getEventCrash: baseDomain + '/event/crash', // 碰撞
  getEventEmergency: baseDomain + '/event/emergency', // 急停

  getAlarm: baseDomain + '/error/alarm', // 报警
  getError: baseDomain + '/error/error', // 报错
  getErrorLog: baseDomain + '/error/errorLog', // 报错日志
  requestErrorLog: baseDomain + '/error/getErrorLogRequest', // 申请下载错误日志
  pullRobotLog: baseDomain + '/error/pullRobotLog', // 拉取机器人日志

  //预测性维护
  getHealthScoreList: baseDomain + '/otaData/collect/healthScoreList', // 健康评分列表
  getHealthScoreTrend: baseDomain + '/otaData/collect/healthScoreTrend', // 健康评分列表
  getCollectTaskList: baseDomain + '/otaData/collect/taskList', // 获取采集任务列表
  requestTerminateTask: baseDomain + '/otaData/collect/terminateTask', // 终止任务
  requestCreateTask: baseDomain + '/otaData/collect/createTask', // 创建数据采集任务
  getCollectRobot: baseDomain + '/otaData/collect/robot', // 机器人本体列表
  getReportList: baseDomain + '/otaData/collect/reportList', // 报文列表
  collectReportDownload: baseDomain + '/otaData/collect/reportDownload', // 报文下载

  
  // 技术论坛
  getFeedbackList: baseDomain + '/feedback/list', // 反馈列表查询
  setFeedBackStatus: baseDomain + '/feedback/setFeedBackStatus', // 设置问题反馈 
  batchDeleteFeedBack: baseDomain + '/feedback/batchDelete', // 批量删除问题反馈 
  getForumList: baseDomain + '/forum/list', // 论坛帖子列表
  addForum: baseDomain + '/forum/add', // 发帖
  deleteForum: baseDomain + '/forum/delete', // 删帖
  getHotList: baseDomain + '/forum/hot', // 热门列表
  getTopList: baseDomain + '/forum/topList', // 置顶列表
  getApplicationList: baseDomain + '/forum/applicationList', // 应用列表
  setTop: baseDomain + '/forum/setTop', // 置顶帖子
  cancelTop: baseDomain + '/forum/cancelTop', // 取消置顶
  setPostStatus: baseDomain + '/forum/setPostStatus', // 设置帖子的状态（显示和隐藏）

  commentlist: baseDomain + '/list', // 评论列表
  subCommentList: baseDomain + '/subList', // 评论的评论列表
  addComment: baseDomain + '/add', // 发表评论
  deleteComment: baseDomain + '/delete', // 删除评论

  // getVirtualApplyList: 'https://ota.jaka.com/virtual/getVirtualApplyList', // 虚拟体验申请列表
  // exportVirtualApplyList: 'https://ota.jaka.com/virtual/excel', // 导出虚拟体验申请列表
  // getClueList: 'https://ota.jaka.com/virtual/sale/getPageList', // 虚拟体验申请列表

  getVirtualApplyList: virtualDomain + '/getVirtualApplyList', // 虚拟体验申请列表
  exportVirtualApplyList: virtualDomain + '/excel', // 导出虚拟体验申请列表
  getClueList: virtualDomain + '/sale/getPageList', // 虚拟体验申请列表


  // 积分管理
  setAdmin: baseDomain + '/userScore/setAdmin', // 设置为公司管理员
  getUserScoreList: baseDomain + '/userScore/list', // 用户积分列表
  getUserScoreDetail: baseDomain + '/userScore/scoreDetailsList', // 用户积分详情
  exportScoreDetails: baseDomain + '/userScore/scoreDetailsExport', // 导出用户积分详情
  
  // getUserScoreDetail: baseDomain + '/userScore/userDetails', // 用户积分明细
  changetUserScore: baseDomain + '/userScore/integralEvent', // 用户积分调整
  getScoreNameList: baseDomain + '/userScore/nameList', // 用户积分项列表
  getScoreStatistics: baseDomain + '/userScore/scoreStatistics', // 用户积分统计

  exchangeList: baseDomain + '/userScore/exchangeList', // 积分兑换列表
  exchangeExport: baseDomain + '/userScore/exchangeExport', // 积分兑换列表
  handleExchangeScore: baseDomain + '/userScore/handle', // 积分兑换列表-处理保存
  rejectExchangeScore: baseDomain + '/goods/fullback', // 积分兑换列表-拒绝兑换

  getGoodsList: baseDomain + '/goods/admin/list', // 积分商品列表
  getGoodsAdmin: baseDomain + '/goods/getGoodsList', // 积分商品列表 积分调减

  // addGoods: baseDomain + '/goods/add', // 增加积分商品
  updateGoods: baseDomain + '/goods/update', // 增加积分商品V
  addGoods: baseDomain + '/goods/v2/add', // 增加积分商品V2
  onShelfGoods: baseDomain + '/goods/v2/grounding', // 商品上架
  offShelfGoods: baseDomain + '/goods/v2/undercarriage', // 商品下架
  setGoodsStock: baseDomain + '/goods/v2/setExchangeQuantity', // 设置商品库存

  deleteGoods: baseDomain + '/goods/delete', // 删除积分商品
  getCategoryList: baseDomain + '/category/admin/list', // 奖品类别列表
  addCategory: baseDomain + '/category/add', // 增加奖品类别
  deleteCategory: baseDomain + '/category/delete', // 删除奖品类别
  updateCategory: baseDomain + '/category/update', // 修改奖品类别
  getTaskList: baseDomain + '/task/admin/list', // 积分任务列表
  addTask: baseDomain + '/task/add', // 新增积分任务
  updateTask: baseDomain + '/task/update', // 修改积分任务

  getRecScoreList: baseDomain + '/user/recommender/getPageList', // 推荐积分列表
  getRecUserList: baseDomain + '/user/pageList', // 推荐用户列表
  exportRecUserList: baseDomain + '/user/userRecommendExport', // 导出推荐用户列表

  // 访客登记
  getVisitorList: baseDomain + '/visitor/list', // 访客登记列表
  searchEmp: baseDomain + '/visitor/searchEmp', // 根据姓名模糊查找被访人

  // 市场活动
  activityWXacode: activityDomain + '/activity/download/customer', // 下载二维码(联合活动客户)
  activityAllWXacode: activityDomain + '/activity/download/all', // 打包下载二维码(联合活动客户)
  getCustomerList: activityDomain + '/activity/customerList', // (模糊查询)客户下拉列表
  activityType: activityDomain + '/activity/activity/type', // 活动类型
  activityStatus: activityDomain + '/activity/status', // 活动状态
  syncActivity: activityDomain + '/activity/syncActivity', // 同步活动
  syncCustomer: activityDomain + '/activity/syncCustomer', // 同步客户
  publishActivity: activityDomain + '/activity/publish', // 活动发布
  stopActivity: activityDomain + '/activity/stop', // 活动中止

  saveActivity: activityDomain + '/activity/save', // 新建活动
  getActivityList: activityDomain + '/activity/list', // 活动列表
  getActivityDetail: activityDomain + '/activity/detail', // 活动详情
  editActivity: activityDomain + '/activity/edit', // 活动编辑
  getReviewDetail: activityDomain + '/activity/review', // 展后详情
  editReviewDetail: activityDomain + '/activity/review/edit', // 展后编辑
  getSignUpList: activityDomain + '/activity/siginUp/list', // 报名列表
  exportSignUpList: activityDomain + '/activity/signUp/export', // 导出报名列表
  getSignInList: activityDomain + '/activity/signIn/list', // 打卡列表
  exportSignInList: activityDomain + '/activity/signIn/export', // 导出打卡列表
  getScoreList: activityDomain + '/activity/score/list', // 积分列表
  unionActivityList: activityDomain + '/activity/unionActivityList', // 签到码列表
  
  // 培训管理
  getTrainingList: baseDomain + '/training/list', // 培训列表
  addTraining: baseDomain + '/training/add', // 新建培训
  editTraining: baseDomain + '/training/edit', // 编辑培训
  removeTraining: baseDomain + '/training/remove', // 删除培训
  qrCodeTraining: baseDomain + '/training/qrCode', // 下载二维码
  getTrainingResult: baseDomain + '/training/listResult', // 培训满意度结果查询

  // 获取关节状态
  getJointFields: queryDomain + '/data/fields', // 获取关节属性列表
  getJointData: queryDomain + '/data/jointData', // 关节数据折线图
  exportJointData: queryDomain + '/data/export', // 导出数据文件

  // 新机上线活动
  packetJoinList: activityDomain + '/activity/red/packet/getOnlinePageList', // 参与记录
  exportPacketJoin: activityDomain + '/activity/red/packet/export', // 参与记录
  packetReceiveList: activityDomain + '/activity/red/receive/getPageList', // 领取记录
  exportPacketReceive: activityDomain + '/activity/red/receive/export', // 导出红包记录
  getRedAmountCount: activityDomain + '/activity/red/receive/getRedAmountCount', // 获取待发送红包金额

  packetApprove: activityDomain + '/activity/red/packet/approved', // 审核通过
  packetReject: activityDomain + '/activity/red/packet/auditRecet', // 审核拒绝
  packetDelete: activityDomain + '/activity/red/packet/delete', // 删除
  packetReceiveSave: activityDomain + '/activity/red/receive/save', // 保存领取记录

  getSalePageList: baseDomain + '/sale/getPageList', // 查询销售收集列表
  exportSalePageList: baseDomain + '/sale/export', // 导出销售收集列表

  getBiRobotStatusInfo: biDomain + '/robotConfig/robotGroupInfos', // 机器人运行状态接口
  getBiRobotStatusDict: biDomain + '/robotConfig/robotStatusDict', // 机器人运行状态枚举接口

  // banner
  getBannerPage: activityDomain + '/activity/banner/queryPage', // banner列表
  editBanner: activityDomain + '/activity/banner', // banner编辑
  shelfBanner: activityDomain + '/activity/banner/', // banner上下架

  getContentPage: activityDomain + '/activity/banner/content/queryPage', // content分页列表
  getContentList: activityDomain + '/activity/banner/contentList', // content列表
  editContent: activityDomain + '/activity/banner/content', // content编辑
  deleteContent: activityDomain + '/activity/banner/content/', // content删除

  // 教育资源
  getResourceList: baseDomain + '/education/queryPage', // 查询资源列表
  editResource: baseDomain + '/education', // 新增或编辑教育资源
  deleteResource: baseDomain + '/education/', // 删除教育资源
  countResource: baseDomain + '/education/updateScanOrDownloadTime', // 更新浏览和下载次数
  
}

export {
  APP_ENV,
  baseDomain,
  activityDomain,
  wsDomain,
  urlMap,
}